<template>
    <div class="kalender-container">
        <LoadingIcon :isLoading="isLoading" />
        <div>
        <v-container class="pa-0">
            <v-row no-gutters class="event-banner">
                <v-col>
                    <v-chip class="prev-event-count" v-show="upcomingCountInPastMonths > 0" color="primary">{{`+ ${upcomingCountInPastMonths}`}}</v-chip>
                    <v-chip class="next-event-count" v-show="upcomingCountInNextMonths > 0" color="primary">{{`+ ${upcomingCountInNextMonths}`}}</v-chip>
                <ImageDiv :imageSource="bannerSource" :templateClass="'bannerImg'" />
                <div class="event-calendar pa-3">
                        <template>
                            <v-row justify="space-between">
                            <div>
                                <v-date-picker
                                :first-day-of-week="1"
                                locale="NL-nl"
                                full-width
                                full-height
                                v-model="firstEventOftheMonth"
                                :picker-date.sync="pickerDate"
                                :events="arrayEvents"
                                :event-color="date => getEventColor(date)"
                                readonly
                                ></v-date-picker>
                            </div>
                            </v-row>
                        </template>
                    </div>
                </v-col>
            </v-row>
        </v-container>
        <div class="px-2">
            <v-container>
                <v-row no-gutters>
                    <v-col>
                        <p class="display-1 mb-2">Roadtrips in {{nextRoadtripDate}}</p>
                        <div>{{intro}}</div>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        </div>
        <v-container>
            <v-row no-gutters>
                <template>
                    <v-col v-for="event in events" :key="event.productId" class="col-lg-6 col-md-12 col-sm-12 col-xs-12" cols="12">
                        <v-container class="event-thumbnail">
                            <v-row>
                                <v-col cols="12" class="pa-0 d-inline-flex" style="flex-wrap: inherit;">
                                    <div class="event-name my-0 py-0 px-1 flex-grow-1 flex-shrink-0">{{event.productName}}</div>
                                    <div small class="date-display my-0 px-1 event-name font-weight-thin text-end text-lowercase flex-grow-0 flex-shrink-1">{{formatDayMonth(event.date)}}</div>
                                </v-col>
                                <v-col cols="12" class="header-title my-0 py-0 px-1 position-relative">
                                    <v-alert class="tag ma-0 pa-0 text-center" :class="event.labelColor"
                                        text--white
                                        width="100"
                                        dense
                                        prominent
                                        text>  {{event.label}}</v-alert>
                                    <v-alert class="ma-0 py-0 px-2 text-center product-status-tag" v-if="isAdmin"
                                        text--white :class="{'online' : !isPrivate(event.productStatus), 'private' : isPrivate(event.productStatus)}"
                                        dense
                                        prominent
                                        text>{{ isPrivate(event.productStatus) ? 'Private' : 'Online' }}</v-alert>
                                </v-col>
                                <v-col cols="12" class="event-photo px-1 pb-0">
                                    <ImageRedirect @imgClick="goToCalendar(event.productName, formatDate(event.date))" :imageSource="event.productPhotoURL" />
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-col>
                </template>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import LoadingIcon from '@/components/common/LoadingIcon';
import ImageDiv from '@/components/common/ImageDiv'
import ImageRedirect from '@/components/common/ImageRedirect'
import FormatMixin from "@/mixins/format.mixin";
import RouteMixin from "@/mixins/route.mixin";
import RoadtripMixin from "@/mixins/roadtrip.mixin";
import MenuHelperMixin from "@/mixins/menu-helper.mixin";
import MetaTagsMixin from "@/mixins/metatags.mixin";
import { mapState } from 'vuex';
import { ProductStatusEnum } from '@/enums/ProductStatusEnum';

export default {
    name: 'Roadtrips',
    components: 
    {
        ImageDiv,
        ImageRedirect,
        LoadingIcon,
        ProductStatusEnum
    },
    mixins: [FormatMixin, RouteMixin, RoadtripMixin, MenuHelperMixin, MetaTagsMixin],
    data: () => ({
        bannerSource:  require('@/assets/album/roadtrip_cover.jpg'),
        intro: "Benieuwd naar wanneer we onze volgende evenementen gepland hebben? Gebruik de agenda om de maand te specificeren die u wilt bekijken. Alle relevante roadtrips worden dan hieronder per categorie weergegeven.",
        isLoading: true,
        events: [],
        pickerDate: null,
        firstEventOftheMonth: null,
        nextRoadtripDate: '',
        upcomingCountInNextMonths: 0,
        upcomingCountInPastMonths: 0,
        metatag: 'Porshe, Volvo, BMW',
        hasRetrievedNextTripDate: false
    }),
    metaInfo() {
        return {
        title: "| Kalender",
        meta: [
            this.getKeywords(`${this.metatag}`),
            this.getMetaDescription(this.metatag),
            this.getAuthor(),
            ...this.getSocialMediaMetas(`Roadpursuit Kalender - ${this.metatag}`, `Roadpursuit Kalender - ${this.metatag}`, `${window.location.origin}${this.bannerSource}`, this.metatag),
        ]
        };
    },
    beforeCreate(){
        this.$store.dispatch('menuHelper/getRoadtripLabels');
    },
    mounted () 
    {
        this.isLoading = true;
        this.$store.commit('setFooterVisibility', true);
        this.$store.dispatch('productModule/getNextRoadtripDate')
            .then(data => {
                this.pickerDate = this.getDateOnly(data);
                this.nextRoadtripDate = `${this.getYearOnly(this.pickerDate)}`;
                this.hasRetrievedNextTripDate = true;
            })
            .catch(() => {
                this.isLoading = false;
            })
    },
    computed: 
    {
        ...mapState('authorizedModule', ['isAdmin', 'userId']),
        arrayEvents() {
            return this.events.map(x => x.date);
        }
    },
    watch: {
        pickerDate (val) {
            if (this.hasRetrievedNextTripDate)
            {
                this.getRoadtrips(this.getMonthOnly(val), this.getYearOnly(val));
            }
        }
    },
    methods: 
    {
        getRoadtrips(month, year)
        {
            this.isLoading = true;
            this.$store.dispatch('productModule/GetProductsByMonthAndYear', { month: month, year: year, accountId: this.userId})
                .then(data => {
                this.events = [];
                data.collection.forEach(event => {
                    let _event = this.mapRoadtripData(event);
                    this.events.push(_event);
                });

                this.upcomingCountInNextMonths =  data.upcomingCountInNextMonths;
                this.upcomingCountInPastMonths =  data.upcomingCountInPastMonths;

                //this.firstEventOftheMonth = this.events[0].date;
                this.isLoading = false;
            })
            .catch(() => { this.isLoading = false; });
        },
        getEventColor(date) {
            const event = this.events.find(x => x.date == date)
            return event.labelColor;
        },
        isPrivate(statusId)
        {
            return ProductStatusEnum.Private == statusId;
        }
    }
}
</script>

<style>
.content
{
  padding: 10px;
}
.header {
  margin-top: 20px;
  margin-bottom: -10px;
  color: #f7f7f7;
}
.sub-header
{
  color: #f7f7f7;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}
.event-calendar 
{
    background-color: #00000087 !important;
    position: absolute;
    background: transparent;
    top: 0px;
    bottom: 0px;
    max-width: 1080px;
}

.event-calendar .theme--dark.v-picker__body
{
  background-color: transparent !important;
}

.event-calendar .v-picker__title
{
  display: none;
}

.event-banner 
{
    position: relative;
}

.prev-event-count,
.next-event-count {
    z-index: 1;
    position: absolute !important;
    top: 8px;
}

.lg .prev-event-count,
.lg .next-event-count,
.xl .prev-event-count,
.xl .next-event-count {
    top: 38px;
}

.prev-event-count .v-chip__content,
.next-event-count .v-chip__content{
    font-weight: bold;
    font-size: 15px !important;
}

.prev-event-count
{
    left: 50px;
}

.next-event-count {
    right: 50px;
}

#app.lg .v-picker__body,
#app.xl .v-picker__body {
    margin-top: 30px;
}

.event-calendar .theme--dark.v-card {
    background-color: transparent;
}

.event-calendar .theme--dark.v-date-picker-table th, 
.event-calendar .theme--dark.v-date-picker-table .v-date-picker-table--date__week,
.event-calendar .v-date-picker-table .v-btn {
    text-shadow: 1px 1px 1px #000000;
    color: white;
    font-weight: bold;
}

.event-calendar .v-date-picker-table__events
{
    height: 25px;
    z-index: -1;
}

.event-calendar .v-date-picker-table__events div
{
    height: 30px;
    width: 30px;
    margin: 0px !important;
}

.v-application .event-calendar .accent {
    background-color: transparent !important;
}

.theme--dark.v-btn:focus:before,
.event-calendar .theme--dark.v-btn--active:hover::before, 
.event-calendar .theme--dark.v-btn--active::before
{
  opacity: 0;
}

.event-thumbnail
{
   white-space: pre;
   padding-bottom: 40px;
}

.xl .event-thumbnail,
.lg .event-thumbnail
{
    padding: 0px 20px 40px 20px;
}

.event-thumbnail .tag.red
{
  background-color: red !important;
}


.event-photo .v-image  
{
   height: 200px;
}

@media only screen and (max-width: 400px) {
    .event-thumbnail .event-name {
        font-size: 22px !important;
    }
    .event-thumbnail .event-name.date-display {
        font-size: 18px !important;
        padding-top: 4px;
    }

}

.product-status-tag
{
    position: absolute;
    right: 0px;
    top: 0px;
}

.product-status-tag.online
{
    background-color: green !important;
}

.product-status-tag.private
{
    background-color: red !important;
}
</style>
